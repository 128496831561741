'use strict';

$(document).ready(() => {
  //FIT TEXT
  $.fn.fitText = function (kompressor, options) {
    const compressor = kompressor || 1,
      settings = $.extend(
        {
          minFontSize: Number.NEGATIVE_INFINITY,
          maxFontSize: Number.POSITIVE_INFINITY,
        },
        options,
      );

    return this.each(function () {
      const $this = $(this);

      const resizer = function () {
        $this.removeAttr('style');
        if ($(window).width() >= 980) {
          $this.css(
            'font-size',
            Math.max(
              Math.min($this.width() / (compressor * 10), parseFloat(settings.maxFontSize)),
              parseFloat(settings.minFontSize),
            ),
          );
        }
      };

      resizer();

      $(window).on('resize.fittext orientationchange.fittext', resizer);
    });
  };
  if ($('[data-fit]').length > 0) {
    $('[data-fit]').each(function () {
      const $this = $(this);
      let atrr = $this.attr('data-fit');
      $this.removeAttr('style');
      if ((atrr = 'true' && $(window).width() >= 980)) {
        $this.fitText(1.2, { minFontSize: '23px', maxFontSize: '74px' });
      }
    });
  }
});
